.table {
	$this: &;

	* {
		@include scrollbar(12px);
	}

	&__footer {
		position: relative;
		background-color: $color-white;
		padding-top: 30px;

		& .ant-col {
			padding: 10px 20px;

			b {
				font-size: 24px;
			}

			strong {
				font-size: 24px;
			}
		}
	}

	.ant-table-thead {
		.ant-table-cell {
			background-color: $color-select-light;
			color: $color-black;
		}
	}

	.ant-table-body {
		overflow: auto !important;
	}

	.ant-table-cell {
		padding: 10px;
		font-size: 13px;

		a {
			text-decoration: underline;

			&:hover {
				color: $color-black;
			}
		}

		.ant-tag {
			margin: 0;
		}

		&.is-finish-week {
			border-right-color: $color-main !important;
		}
	}

	.ant-table-summary {
		td {
			font-weight: bold;
		}
	}

	.courier-link {
		margin-bottom: 0;
		margin-right: 10px;
		color: $color-main;
		cursor: pointer;
	}

	.ant-table-footer {
		padding: 0;
	}

	.ant-table-row {
		&.no-expand {
			& + .ant-table-expanded-row,
			.ant-table-row-expand-icon,
			.ant-table-row-expand-button {
				display: none;
			}
		}
	}

	.ant-table-expanded-row {
		.ant-table-thead {
			.ant-table-cell {
				background-color: $color-select-light;
			}
		}

		& > .ant-table-cell {
			background-color: transparent;
			padding: 0;
		}

		#{$this}:not(.is-full) {
			.ant-table {
				margin: 0 0 0 47px;
			}
		}

		.expanded-block {
			margin: 0 0 0 47px !important;
		}

		.ant-table-title {
			padding: 10px;
		}
	}

	.ant-table-placeholder {
		.ant-table-expanded-row-fixed {
			width: 100% !important;
			margin: 0 !important;

			&::after {
				display: none;
			}
		}
	}

	.employee-highlight {
		background: $color-select-light;
	}

	.is-arriving {
		background-color: $color-success-light;
	}

	.is-leaving {
		background-color: $color-main-light;
	}

	.is-from-site {
		td:first-child {
			border-left: 3px solid $color-black;
		}
	}

	.is-late {
		background-color: $color-select-light;
	}

	.is-created {
		background-color: $color-success-light;
	}

	.is-delayed {
		background-color: $color-yellow-light;
	}

	.is-delivered {
		background-color: #c497ef;
	}

	.is-delivering {
		background-color: #e4c8ff;
	}

	.is-picked-up {
		background-color: $color-main-lightest;
	}

	.is-courier {
		background-color: $color-main-light;
	}

	.is-expired {
		background-color: $color-red-lightest;
	}

	.no-min {
		background-color: $color-red-light;
	}

	.min-value {
		background-color: $color-yellow-light;
	}

	.is-total {
		background-color: $color-select-light;
	}

	.is-foot {
		td {
			font-weight: bold;
		}
	}

	.is-gray {
		td {
			background: $color-select-light;
		}
	}

	.is-promocode {
		background: $color-main-lightest;
	}

	.is-nomenclature {
		background-color: $color-yellow-light !important;
	}

	&--docs-transactions {
		.ant-table-cell {
			padding: 5px;
			font-size: 10px;
		}

		.ant-table-thead {
			.ant-table-cell {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	&--order {
		.ant-table-cell {
			padding: 5px;
		}
	}
}
