.modal {
	&.ant-modal {
		width: 100% !important;
		max-width: 800px;

		.ant-modal-body {
			position: relative;
			padding: 20px;
			border-radius: 3px;
			background-color: $color-white;

			@include microbe-media($microbe-breakpoint-md) {
				padding: 40px 20px 40px 40px;
			}
		}
	}

	&__close {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 16px;
		height: 16px;
		fill: $color-dark;
		z-index: 2;
		cursor: pointer;

		&:hover {
			fill: $color-error;
		}
	}

	&__title {
		margin-bottom: 20px;
		color: $color-black;
		font-size: 22px;
		line-height: 34px;
		font-weight: 700;

		@include microbe-media($microbe-breakpoint-md) {
			margin-bottom: 30px;
		}

		&--center {
			text-align: center;
		}

		&--small-margin {
			margin-bottom: 5px;
		}
	}

	&__text {
		margin-bottom: 20px;
		color: $color-black;
		font-size: 14px;
	}

	&--small {
		&.ant-modal {
			max-width: 500px;
		}
	}

	&--middle {
		&.ant-modal {
			max-width: 800px;
			top: 24px;
		}
	}

	&--big {
		&.ant-modal {
			max-width: 1200px;
		}
	}

	&--full {
		&.ant-modal {
			max-width: 1200px;

			@include microbe-media(1281px, max) {
				max-width: initial !important;
				width: 100vw;
				padding: 0 !important;
				top: 0 !important;
			}
		}
	}

	&--right {
		&.ant-modal {
			max-width: 1200px;

			@include microbe-media(1281px, max) {
				max-width: 640px !important;
				width: 100vw;
				padding: 0 !important;
				top: 0 !important;
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				margin-right: 0 !important;
			}
		}
	}
}
