.notifications {
	$this: &;

	&__item {
		position: relative;
		padding: 10px 20px 10px 0;

		& + #{$this}__item {
			border-top: 1px solid $color-light;
		}
	}

	&__date {
		font-size: 12px;
		color: $color-gray;
	}

	&__text {
		font-size: 14px;
		color: $color-dark;
	}

	&__close {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 10px;
		height: 10px;
		cursor: pointer;
		fill: $color-dark;
		transition: fill 0.3s;

		svg {
			width: 100%;
			height: 100%;
		}

		&:hover {
			fill: $color-main;
		}
	}

	&__recreate {
		text-decoration: underline;
		color: $color-main;
		cursor: pointer;
		transition: color 0.3s;

		&:hover {
			color: $color-dark;
		}
	}
}
