.layout {
	display: flex;

	&__sidebar {
		flex-shrink: 0;
		height: 100%;
		position: relative;
		z-index: 2;

		.mobile & {
			width: 70px;
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		background-color: $color-light;
		padding: 20px;
		grid-area: content;
		min-height: 100%;
		overflow-x: hidden;
		position: relative;
		z-index: 1;

		@include scrollbar(12px);
	}

	&__wrap {
		background-color: $color-white;
		border-radius: 3px;
		flex-grow: 1;
		padding: 0 20px 20px;
	}

	&__body {
		@include microbe-media($microbe-breakpoint-xl) {
			margin-top: -55px;
		}
	}

	&__content {
		flex-grow: 1;
		width: 100%;
	}

	&__footer {
		flex-shrink: 0;
		width: 100%;
		padding-top: 20px;
	}

	&--base {
		overflow: hidden;
		min-height: 100%;
		flex-direction: column;
	}

	&--main {
		height: 100%;
		width: 100%;
		display: flex;
	}

	&--bg {
		&::before {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}
}
