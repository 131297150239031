.addresses-change {
	&__title {
		display: inline-block;
		width: calc(100% - 24px);
		font-size: 14px;
		font-weight: 700;
		color: $color-black;
		padding-right: 16px;
		transition: color 0.3s;

		&:hover {
			color: $color-main;
		}

		&--error {
			color: $color-error !important;
		}
	}

	.ant-collapse-header {
		display: flex;
		align-items: center;
	}

	.ant-collapse-arrow {
		flex-shrink: 0;
	}

	.ant-collapse-header-text {
		flex-grow: 1;
	}

	.ant-collapse-extra {
		flex-shrink: 0;
	}
}
