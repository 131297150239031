.button {
	$this: &;

	font-size: 16px;
	line-height: 1.2;
	height: 40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 17px;
	transition: color 0.3s, background-color 0.3s, border-color 0.3s;
	text-shadow: none !important;
	box-shadow: none !important;
	cursor: pointer;
	text-decoration: none;
	border-radius: 20px;

	span {
		& + svg {
			margin-left: 16px;
		}

		& + .anticon {
			margin-left: 16px;
		}
	}

	svg {
		fill: currentColor;

		& + span {
			margin-left: 16px;
		}
	}

	& > svg {
		width: 16px;
		height: 16px;
	}

	& > span > svg {
		width: 16px;
		height: 16px;
	}

	.anticon {
		flex-shrink: 0;

		& + span {
			margin-left: 16px;
		}
	}

	&--full {
		width: 100%;
	}

	&--small {
		height: 32px;
		padding: 0 10px;
		font-size: 12px;

		@include microbe-media($microbe-breakpoint-lg) {
			padding: 0 15px;
		}

		& > svg {
			width: 14px;
			height: 14px;
		}

		& > span > svg {
			width: 14px;
			height: 14px;
		}
	}

	&--xs {
		height: 30px;
		padding: 0 10px;
		font-size: 12px;

		@include microbe-media($microbe-breakpoint-lg) {
			padding: 0 15px;
		}
	}

	&--default {
		background-color: $color-white;
		border: 1px solid $color-dark;
		color: $color-dark;

		html.no-mobile &:hover,
		html.mobile &:active {
			border-color: $color-dark;
			background-color: $color-dark;
			color: $color-white !important;
		}
	}

	&--trans {
		color: $color-dark;

		html.no-mobile &:hover,
		html.mobile &:active {
			color: $color-main !important;
		}
	}

	&--main {
		border: 1px solid $color-main;
		background-color: $color-main;
		color: $color-white;

		html.no-mobile &:hover,
		html.mobile &:active {
			border-color: $color-dark;
			background-color: $color-dark;
			color: $color-white !important;
		}
	}

	&--main-border {
		background-color: $color-white;
		border: 1px solid $color-main;
		color: $color-main;

		html.no-mobile &:hover,
		html.mobile &:active {
			border-color: $color-dark;
			color: $color-dark !important;
		}
	}

	&--trans-main {
		color: $color-main;

		html.no-mobile &:hover,
		html.mobile &:active {
			color: $color-dark !important;
		}
	}

	&--main-label {
		border: 1px solid $color-main;
		background-color: $color-main;
		color: $color-white;
		cursor: default;
	}

	&--success {
		border: 1px solid $color-success;
		background-color: $color-success;
		color: $color-white;

		html.no-mobile &:hover,
		html.mobile &:active {
			border-color: $color-dark;
			background-color: $color-dark;
			color: $color-white !important;
		}
	}

	&--error {
		border: 1px solid $color-error;
		background-color: $color-error;
		color: $color-white;

		html.no-mobile &:hover,
		html.mobile &:active {
			border-color: $color-dark;
			background-color: $color-dark;
			color: $color-white !important;
		}
	}

	&--error-label {
		border: 1px solid $color-error;
		background-color: $color-error;
		color: $color-white;
		cursor: default;
	}

	&--trans-error {
		color: $color-error;

		html.no-mobile &:hover,
		html.mobile &:active {
			color: $color-dark !important;
		}
	}

	&--dark {
		border: 1px solid $color-dark;
		background-color: $color-dark;
		color: $color-white;

		html.no-mobile &:hover,
		html.mobile &:active {
			border-color: $color-dark;
			background-color: transparent;
			color: $color-dark;
		}
	}

	&--white {
		border: 1px solid $color-light;
		background-color: $color-white;
		color: $color-dark;

		html.no-mobile &:hover,
		html.mobile &:active {
			border-color: $color-main;
			background-color: $color-white;
			color: $color-dark;
		}
	}

	&--icon {
		padding: 0 !important;
		border: none !important;
		width: 30px;
		height: 30px;

		.anticon {
			font-size: 28px;
			width: 100% !important;
			height: 100% !important;
		}

		svg {
			width: 100% !important;
			height: 100% !important;
		}
	}

	&--icon-small {
		width: 40px;
		height: 40px;
		padding: 0 !important;

		& > svg {
			width: 20px;
			height: 20px;
		}

		.anticon {
			width: 20px;
			height: 20px;

			& > svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	&--icon-sm {
		width: 40px;
		height: 40px;
		padding: 0 !important;

		& > svg {
			width: 15px;
			height: 15px;
		}

		.anticon {
			width: 15px;
			height: 15px;

			& > svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	&--icon-def {
		width: 40px;
		height: 40px;
		padding: 0 !important;

		& > svg {
			width: 25px;
			height: 25px;
		}

		.anticon {
			width: 25px;
			height: 25px;

			& > svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	&--icon-xs {
		width: 30px;
		height: 30px;
		padding: 0 !important;

		& > svg {
			width: 18px;
			height: 18px;
		}

		.anticon {
			width: 20px;
			height: 20px;

			& > svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	&--icon-close {
		width: 15px;
		height: 15px;
		padding: 0 !important;
		color: $color-error;

		& > svg {
			width: 15px;
			height: 15px;
		}

		.anticon {
			width: 15px;
			height: 15px;

			& > svg {
				width: 100%;
				height: 100%;
			}
		}

		html.no-mobile &:hover,
		html.mobile &:active {
			color: $color-dark;
		}
	}

	&--tag {
		cursor: default;
	}

	&--gray {
		border-color: $color-light;
		background-color: $color-light;
		color: $color-dark;

		html.no-mobile &:hover,
		html.mobile &:active {
			border-color: $color-select;
			background-color: $color-select;
		}
	}

	&[disabled] {
		cursor: not-allowed !important;
		border: 1px solid $color-light !important;
		background-color: $color-light !important;
		color: $color-gray !important;

		html.no-mobile &:hover,
		html.mobile &:active {
			border: 1px solid $color-light !important;
			background-color: $color-light !important;
			color: $color-gray !important;
		}
	}

	&--icon-left {
		svg {
			margin-right: 8px;
		}
	}

	&--no-border {
		border-radius: 3px;
	}
}
