.nomenclature-block {
	margin-bottom: 20px;

	&__categories {
		display: flex;
		flex-direction: column;
	}

	&__title {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 25px;
	}

	&__search {
		margin-bottom: 10px;

		input {
			border-radius: 3px;
			padding: 0 10px;
			height: 38px;
			box-shadow: none !important;
		}
	}

	&__list {
		max-height: 640px;
		overflow-x: hidden;
	}

	&__item {
		margin-bottom: 5px;

		&.is-hide {
			display: none;
		}
	}
}
