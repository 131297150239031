.orders-statistic {
	display: flex;
	margin-bottom: 20px;

	&__button {
		flex-shrink: 0;
		padding-right: 10px;
	}

	&__list {
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px -10px;
	}

	&__item {
		padding: 0 5px 10px;
	}

	&__tag {
		display: flex;
		align-items: center;
		height: 40px;
		padding: 0 14px;
		margin: 0;
		font-size: 14px;
		cursor: pointer;
		transition: background-color 0.3s, box-shadow 0.3s;
		background-color: $color-gray-light;

		&::after {
			display: none;
		}

		svg {
			display: inline-block;
			width: 24px;
			height: 24px;
			margin-right: 5px;
		}

		span {
			margin-right: 5px;
		}

		&.is-active {
			cursor: default;
			background-color: $color-white;
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		}

		&:not(.is-active):not(.is-not-active):hover {
			border-color: $color-main;
		}

		&.is-not-active {
			cursor: default;
		}
	}
}
