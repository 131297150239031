.count-input {
	display: inline-block;
	max-width: 100%;

	.ant-input-group {
		display: flex;
		align-items: center;
	}

	.ant-input-group-addon {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 !important;
		flex-shrink: 0;
		width: 32px;
		border: none;
		z-index: 10;
		background-color: transparent;
	}

	.ant-input {
		flex-grow: 1;
		text-align: center;
		width: 109px;
		height: 32px;
		padding: 0 32px;
		border-radius: 16px !important;
		background: $color-select-light;

		&:focus {
			box-shadow: none;
		}

		& + .ant-input-group-addon {
			left: auto;
			right: 0;
		}
	}

	&__minus,
	&__plus {
		border-radius: 16px !important;
		transition: color 0.3s, border-color 0.3s;
	}

	&__minus {
		border-color: $color-gray;
		color: $color-gray;

		&:hover {
			border-color: $color-black;
			color: $color-black;
		}
	}

	&__plus {
		border-color: $color-main;
		color: $color-main;

		&:hover {
			border-color: $color-black;
			color: $color-black;
		}
	}
}
