.footer {
	display: flex;
	justify-content: space-between;
	margin: 0 -10px;

	&__item {
		padding: 0 10px;
	}

	&__text {
		color: $color-dark;
		font-size: 14px;

		span {
			margin-right: 5px;

			@include microbe-media($microbe-breakpoint-md, max) {
				display: none;
			}
		}

		a {
			display: inline-block;
			text-decoration: underline;
			color: $color-main;
			transition: color 0.3s;
			font-size: 16px;

			&:hover {
				color: $color-black;
			}
		}
	}

	&__wezom {
		display: block;
		color: $color-dark;
		font-size: 14px;

		span {
			margin-right: 10px;

			@include microbe-media($microbe-breakpoint-md, max) {
				display: none;
			}
		}

		a {
			display: inline-block;
			text-decoration: underline;
			fill: $color-dark;
			transition: fill 0.3s;

			svg {
				display: block;
				height: 14px;
			}

			&:hover {
				fill: $color-main;
			}
		}
	}
}
