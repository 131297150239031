.header {
	overflow-y: hidden;

	&__content {
		display: inline-flex;
		min-width: 100%;
		justify-content: flex-end;
		overflow: hidden;
		padding: 20px 0;
	}

	&__list {
		display: flex;
		align-items: center;
		margin: 0 -12px -12px;
		position: relative;
		z-index: 10;
	}

	&__item {
		padding: 0 12px 12px;

		&--stat {
			margin-right: 40px;
		}
	}

	&__user {
		display: inline-block;
		text-align: right;
	}

	&__user-title {
		font-size: 14px;
		font-weight: bold;
		color: $color-dark;
		line-height: 1.25;
	}

	&__user-text {
		font-size: 14px;
		color: $color-dark;
		line-height: 1.25;
	}
}
