.custom-table {
	width: 100%;
	overflow: auto;
	font-size: 13px;
	font-weight: normal;
	color: $color-black;
	border: 1px solid #f0f0f0;

	@include scrollbar(12px);

	table {
		td,
		th {
			text-align: left;
			vertical-align: top;
			padding: 10px;
		}

		thead {
			tr {
				th {
					background-color: $color-select-light;
					border-bottom: 1px solid $color-select;

					&:not(:last-child) {
						border-right: 1px solid $color-select;
					}
				}
			}
		}

		tr {
			&:not(:last-child) {
				td,
				th {
					border-bottom: 1px solid $color-select;

					&:not(:last-child) {
						border-right: 1px solid $color-select;
					}
				}
			}
		}

		tfoot {
			td {
				font-weight: bold;
			}
		}
	}

	&--schedule {
		height: 600px;

		@media print {
			height: auto;
		}
	}
}
