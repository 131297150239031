.title {
	margin-bottom: 0;
	color: $color-dark;
	font-weight: 600;

	&--size-h2 {
		font-size: 30px;
		line-height: 40px;
	}
}
