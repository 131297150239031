.cashbox {
	&__item {
		display: flex;
		align-items: center;
	}

	&__item-label {
		margin-right: 8px;
		color: $color-gray;
	}

	&__item-value {
		color: $color-dark;
		font-weight: 700;

		&--inflow {
			color: $color-success;
		}

		&--outflow {
			color: $color-error;
		}
	}
}
