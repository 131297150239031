.shift-cell {
	$this: &;

	color: $color-gray;
	font-size: 12px;
	font-weight: 700;
	text-align: center;
	padding: 5px;
	position: relative;
	height: 100%;

	@media print {
		font-size: 10px;
	}

	&__text {
		color: $color-gray;
		white-space: nowrap;
	}

	&__weekday {
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;

		@media print {
			font-size: 10px;
		}
	}

	&__day {
		color: $color-dark;
		border-bottom: 1px solid $color-gray;
		margin-bottom: 3px;
	}

	&__divider {
		margin: 0 0 10px;
	}

	&__info {
		max-width: 400px;
		line-height: 1.5;
	}

	&__holiday {
		color: $color-main;
		margin-bottom: 10px;
	}

	&__triangle {
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 10px 10px 0;
		border-top-color: transparent;
		border-bottom-color: transparent;
		border-left-color: transparent;
		display: none;
	}

	&--employee {
		text-align: left;
	}

	&--head,
	&--day {
		width: 50px;

		@media print {
			width: 45px;
		}

		&.is-current-day {
			background-color: $color-success-light;
		}
	}

	&--day {
		min-height: 65px;
		cursor: pointer;

		&:not(.is-current-day):hover {
			background-color: $color-light !important;
		}

		&.is-current-day {
			cursor: default;
			background-color: $color-success-light;
		}

		&.is-label #{$this}__triangle {
			display: block;
		}
	}
}
