.filter {
	$this: &;

	display: flex;
	margin-bottom: 20px;
	margin-left: -10px;
	margin-right: -10px;

	&__buttons {
		flex-shrink: 0;
		padding-top: 25px;
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		margin: 0 -5px -10px;

		@include microbe-media($microbe-breakpoint-df, max) {
			flex-direction: column;
		}
	}

	&__button {
		padding: 0 5px 10px;

		&--lg-show {
			@include microbe-media(1281px, max) {
				display: none;
			}
		}
	}

	&__info {
		flex-shrink: 0;
		padding-left: 10px;
		padding-right: 10px;
	}

	&__form {
		flex-grow: 1;
		padding-left: 10px;
		padding-right: 10px;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px -10px;
	}

	&__col {
		width: 50%;
		padding: 0 5px 10px;
	}

	&__cells {
		padding-top: 25px;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px -10px;
	}

	&__cell {
		padding: 0 5px 10px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px -10px;
	}

	&__item {
		padding: 0 5px 10px;

		&--center {
			display: flex;
			justify-content: center;
		}
	}

	&__text-block {
		padding-top: 15px;
		font-size: 16px;
		font-weight: bold;
	}

	&--3 {
		#{$this}__item {
			width: 100%;

			@include microbe-media($microbe-breakpoint-md) {
				width: 50%;
			}

			@include microbe-media($microbe-breakpoint-df) {
				width: 33.333%;
			}
		}
	}

	&--4 {
		#{$this}__item {
			width: 100%;

			@include microbe-media($microbe-breakpoint-md) {
				width: 50%;
			}

			@include microbe-media($microbe-breakpoint-df) {
				width: 25%;
			}
		}
	}

	&--5 {
		#{$this}__item {
			width: 100%;

			@include microbe-media($microbe-breakpoint-md) {
				width: 50%;
			}

			@include microbe-media($microbe-breakpoint-df) {
				width: 33.3333%;
			}

			@include microbe-media($microbe-breakpoint-lg) {
				width: 20%;
			}
		}
	}

	&--6 {
		#{$this}__item {
			width: 100%;

			@include microbe-media($microbe-breakpoint-md) {
				width: 50%;
			}

			@include microbe-media($microbe-breakpoint-df) {
				width: 33.3333%;
			}

			@include microbe-media($microbe-breakpoint-lg) {
				width: 16.6667%;
			}
		}
	}

	&--7 {
		#{$this}__item {
			width: 100%;

			@include microbe-media($microbe-breakpoint-md) {
				width: 50%;
			}

			@include microbe-media($microbe-breakpoint-df) {
				width: 33.3333%;
			}

			@include microbe-media($microbe-breakpoint-lg) {
				width: 14.285%;
			}
		}
	}

	&--transactions {
		#{$this}__item {
			width: 100%;

			@include microbe-media($microbe-breakpoint-md) {
				width: 50%;
			}

			@include microbe-media($microbe-breakpoint-df) {
				width: 33.3333%;
			}

			@include microbe-media($microbe-breakpoint-lg) {
				width: 18%;
			}

			&--calendar {
				@include microbe-media($microbe-breakpoint-lg) {
					width: 27%;
				}
			}
		}
	}

	&--cashbox {
		@include microbe-media($microbe-breakpoint-lg, max) {
			flex-wrap: wrap;
		}

		#{$this}__item {
			width: 100%;

			@include microbe-media($microbe-breakpoint-md) {
				width: 50%;
			}

			@include microbe-media($microbe-breakpoint-df) {
				width: 300px;
			}
		}

		#{$this}__form {
			margin-bottom: 0;

			@include microbe-media($microbe-breakpoint-lg, max) {
				width: 100%;
			}
		}

		#{$this}__info {
			@include microbe-media($microbe-breakpoint-lg, max) {
				width: 100%;
			}
		}
	}

	&--docs-transactions {
		html.is-collapsed & {
			@include microbe-media(1770px) {
				margin-top: -80px;
			}
		}

		#{$this}__list {
			justify-content: flex-end;
		}

		#{$this}__item {
			width: 150px;
		}
	}
}
