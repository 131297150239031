.product-search {
	position: relative;

	&__container {
		display: flex;
		margin: 0 -6px -12px;
		padding-bottom: 20px;

		@include microbe-media($microbe-breakpoint-md, max) {
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__link {
		flex-shrink: 0;
		padding: 0 6px 12px;

		@include microbe-media($microbe-breakpoint-md) {
			order: 1;
		}

		@include microbe-media($microbe-breakpoint-md, max) {
			width: 100%;
		}
	}

	&__button {
		flex-shrink: 0;
		padding: 0 6px 12px;

		@include microbe-media($microbe-breakpoint-md) {
			order: 3;
		}
	}

	&__input {
		flex-grow: 1;
		padding: 0 6px 12px;
		width: 100%;

		@include microbe-media($microbe-breakpoint-sm) {
			width: 50%;
		}

		@include microbe-media($microbe-breakpoint-md) {
			order: 2;
		}
	}

	&__list {
		margin-bottom: 20px;
		padding: 10px 20px;
		border-radius: 10px;
		background-color: $color-select-light;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin-left: -8px;
		margin-top: -8px;
	}

	&__col {
		padding-left: 8px;
		padding-top: 8px;
		width: 100%;

		@include microbe-media(640px) {
			width: 50%;
		}

		@include microbe-media($microbe-breakpoint-df) {
			width: 33.3333%;
		}

		@include microbe-media(1260px) {
			width: 25%;
		}
	}
}
