.product-search-card {
	$this: &;

	height: 100%;
	padding: 10px;
	background-color: $color-white;
	position: relative;
	border: 1px solid rgba(158, 158, 158, 0.25);
	border-radius: 4px;
	transition: box-shadow 0.3s;

	&:hover {
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
	}

	&__title {
		font-weight: 600;
		font-size: 18px;
		color: $color-black;
		margin-bottom: 5px;
	}

	&__text {
		font-size: 14px;
		color: $color-black;
		margin-bottom: 5px;
	}

	&__row {
		display: flex;
	}

	&__image {
		flex-shrink: 0;
		padding-right: 10px;
		max-width: 40%;
	}

	&__info {
		flex-grow: 1;
	}

	&__size {
		margin-bottom: 5px;
	}

	&__block {
		margin-bottom: 10px;
	}

	&__button {
		width: 109px;
	}

	.ant-radio-group {
		display: flex;
	}

	.ant-radio-wrapper {
		font-size: 12px;
		display: block;
	}

	span.ant-radio + * {
		padding-right: 0;
		padding-left: 5px;
	}

	.ant-avatar {
		width: 75px;
		height: 75px;
		max-width: 100%;

		@include microbe-media($microbe-breakpoint-df) {
			width: 120px;
			height: 120px;
		}

		img {
			object-fit: contain;
		}
	}

	&--ingredientable {
		&::after {
			content: '';
			position: absolute;
			top: -1px;
			right: -1px;
			z-index: 1;
			display: block;
			width: 0;
			height: 0;
			margin: 0;
			padding: 0;
			border-width: 6px;
			border-style: solid;
			border-color: $color-select $color-select transparent transparent;
		}
	}

	&--reverse {
		#{$this}__row {
			flex-direction: row-reverse;
		}
	}
}
