.link {
	color: $color-main;
	text-decoration: underline;
	transition: color 0.3s;
	cursor: pointer;

	&:hover {
		color: $color-dark;
	}
}
