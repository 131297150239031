.sidebar {
	$this: &;

	background-color: $color-white;
	height: 100%;
	overflow-x: hidden;
	grid-area: sidebar;
	position: relative;
	display: inline-block;

	&__container {
		min-height: 100%;

		.ant-layout-sider-has-trigger {
			padding-bottom: 35px;
		}

		.ant-layout-sider-trigger {
			height: 35px;
			line-height: 35px;
		}

		&.ant-layout-sider-collapsed {
			#{$this}__head {
				padding: 10px !important;
			}

			#{$this}__full-image {
				position: absolute;
				opacity: 0;
			}

			#{$this}__small-image {
				position: static;
				opacity: 1;
			}

			#{$this}__link {
				span {
					opacity: 0;
				}
			}
		}
	}

	&__head {
		padding: 10px 20px;
	}

	&__logo {
		display: block;
		overflow: hidden;
		position: relative;

		img {
			height: 35px;
			top: 0;
			left: 0;
		}
	}

	&__full-image {
		max-width: 210px;
	}

	&__small-image {
		max-width: 50px;
		position: absolute;
		opacity: 0;
	}

	&__menu {
		margin: 20px 0;
	}

	&__link {
		display: flex;
		align-items: center;
		text-decoration: none;
		padding: 15px 20px;
		transition: background-color 0.3s, color 0.3s;
		color: $color-black;
		overflow: hidden;
		cursor: pointer;

		span {
			margin-left: 10px;
			white-space: nowrap;
		}

		svg {
			width: 30px;
			height: 30px;
			flex-shrink: 0;
		}

		&--active {
			color: $color-black !important;
			background-color: $color-select;
		}

		&:hover {
			color: $color-main;
		}
	}

	.mobile &:not(&--collapsed) {
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
	}
}
