table.table-schedule {
	font-size: 13px;
	font-weight: normal;
	color: $color-black;
	height: 1px;

	@media print {
		height: auto;
		font-size: 10px;
	}

	tr {
		&:not(:last-child) {
			td,
			th {
				border-bottom: 1px solid $color-select;

				&:not(:last-child) {
					border-right: 1px solid $color-select;
				}
			}
		}
	}

	td,
	th {
		text-align: left;
		vertical-align: top;
		padding: 0;

		&:first-child {
			vertical-align: middle;
		}

		&.is-finish-week {
			border-right-color: $color-main !important;
		}

		&.is-first {
			position: sticky;
			left: 0;
			z-index: 10;
			border-right-color: $color-main !important;

			@media print {
				position: static;
			}
		}
	}

	thead {
		position: sticky;
		top: 0;
		z-index: 20;

		tr {
			th {
				border-bottom: 1px solid $color-select;
				background-color: $color-main-light;
				border-bottom-color: $color-main;

				&:not(:last-child) {
					border-right: 1px solid $color-select;
				}

				&:first-child {
					background-color: $color-select-light;
				}

				&.is-week {
					background-color: $color-red-light;
				}
			}
		}

		@media print {
			position: static;
		}
	}

	tbody {
		tr {
			&:nth-child(odd) {
				td {
					background-color: $color-main-lightest;

					&:first-child {
						background-color: $color-white;
					}

					&.is-week {
						background-color: $color-red-lightest;
					}
				}
			}

			&:nth-child(even) {
				td {
					background-color: $color-main-light;

					&:first-child {
						background-color: $color-select-light;
					}

					&.is-week {
						background-color: $color-red-light;
					}
				}
			}
		}
	}

	tfoot {
		td {
			font-weight: bold;
		}
	}
}
