.document {
	border: 1px solid;
	padding: 20px;

	&__head {
		text-align: center;
	}

	&__select {
		text-decoration: underline;
		font-weight: bold;
	}

	&__help {
		font-size: 10px;
	}

	&__underline {
		text-decoration: underline;
	}

	&--cash-warrant {
		width: 8.3cm;
	}
}
