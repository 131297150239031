.error {
	$this: &;

	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 20px;

	&::before {
		content: '';
		display: block;
		width: 0;
		height: calc(100vh - 80px);
	}

	&__container {
		width: 700px;
		max-width: 100%;
		background-color: $color-white;
		box-shadow: 0 4px 24px rgba($color-black, 0.08);
		padding: 30px;
		text-align: center;
		border-radius: 5px;
		position: relative;
		z-index: 1;

		@include microbe-media($microbe-breakpoint-df) {
			padding: 50px;
		}
	}

	&__code {
		color: $color-main;
		font-weight: bold;
		font-size: 100px;
		line-height: 1.1;

		@include microbe-media($microbe-breakpoint-df) {
			font-size: 250px;
		}
	}

	&__title {
		color: $color-dark;
		line-height: 1.2;
		font-size: 30px;
		margin-bottom: 30px;

		@include microbe-media($microbe-breakpoint-df) {
			font-size: 40px;
		}
	}

	&__text {
		font-size: 16px;
		color: $color-dark;
		margin-bottom: 30px;
	}
}
