#print-container {
	display: none;
}

.is-print {
	display: none;
}

@media print {
	.is-visually-hidden {
		> * {
			display: none;
		}
	}

	.target-print-styles {
		.ant-table-thead > tr > th,
		.ant-table-tbody > tr > td {
			padding: 4px 8px !important;
		}

		.ant-table-selection-column {
			display: none !important;
		}

		.no-print {
			display: none !important;
		}
	}

	.is-print {
		display: block;
	}

	.is-not-print {
		display: none;
	}

	#print-container {
		display: block !important;
	}

	.is-print-before {
		page-break-before: always;
	}

	.is-print-after {
		page-break-after: always;
	}
}
