@mixin scrollbar($size: 17px, $color-track: $color-light, $color-thumb: $color-black) {
	&::-webkit-scrollbar-track {
		background-color: rgba($color-track, 1);
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba($color-thumb, 0.8);

		&:hover {
			background-color: rgba($color-thumb, 1);
		}
	}

	&::-webkit-scrollbar {
		width: $size;
		height: $size;
	}
}
