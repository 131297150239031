.order-composition {
	margin-top: 20px;

	&__container {
		display: flex;
		margin: 0 -6px -12px;

		@include microbe-media($microbe-breakpoint-md, max) {
			flex-wrap: wrap;
			justify-content: flex-end;
		}
	}

	&__button {
		flex-shrink: 0;
		padding: 0 6px 12px;
	}

	&__content {
		flex-grow: 1;
		padding: 0 6px 12px;
	}

	&__text {
		color: $color-gray;
		font-size: 14px;
	}

	&__sum {
		color: $color-black;
		font-weight: 500;
		font-size: 14px;
	}

	&__total {
		color: $color-black;
		font-weight: 500;
		font-size: 24px;
	}

	&__col-icon {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-content: center;
		transform: translate(30px, 8px);

		svg {
			fill: $color-main;
			width: 12px;
			height: 12px;
		}
	}
}
