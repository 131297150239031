.order-form {
	$this: &;

	&__button {
		flex-shrink: 0;
		padding: 0 6px 12px;

		@include microbe-media($microbe-breakpoint-lg) {
			order: 3;
			margin-top: 25px;
		}
	}

	&__title {
		font-size: 32px;
		color: $color-black;
		margin: 10px 0;
	}

	&__stat-row {
		width: 100%;
		flex-wrap: wrap;
	}

	&__history {
		padding-bottom: 20px;
	}

	&__sum-block {
		background-color: $color-select-light;
		padding: 8px;
	}

	&__sum-row {
		display: flex;
		justify-content: space-between;

		& + #{$this}__sum-row {
			margin-top: 20px;
		}
	}

	&__sum-title {
		color: $color-gray;
		font-size: 16px;
	}

	&__sum-value {
		font-weight: 500;
		font-size: 16px;
		color: $color-dark;
	}

	&__total-block {
		display: flex;
		justify-content: flex-end;
		margin-top: 24px;
	}

	&__total-title {
		font-weight: 500;
		font-size: 24px;
		color: $color-dark;
	}
}
