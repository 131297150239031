.product-components {
	.ant-popover-content {
		width: 206px !important;
		margin-top: -12px !important;
	}
	.ant-popover-inner {
		background-color: $color-light !important;
	}
	.ant-popover-arrow {
		border-bottom-color: $color-light !important;
		border-left-color: $color-light !important;
	}
	.ant-popover-title {
		border-bottom-color: $color-gray !important;
	}
}
