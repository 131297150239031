.ant-picker-dropdown {
	&.ant-picker-digit-reverse {
		.ant-picker-time-panel-column {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}

.rc-virtual-list {
	.rc-virtual-list-scrollbar {
		width: 4px !important;
		background-color: #e8e8e8 !important;
		border-radius: 2px !important;

		.rc-virtual-list-scrollbar-thumb {
			background-color: #000000 !important;
		}
	}
}

.ant-popover-inner-content {
	max-width: 500px;
	max-height: 315px;
	overflow-x: hidden;
}

.ant-table-filter-trigger {
	color: #888888;

	&.active,
	&:hover {
		color: #000000;
	}
}

.ant-layout-sider,
.ant-layout-sider-trigger {
	transition: none;
}

.ant-input-number-handler-wrap {
	opacity: 1 !important;
}

.ant-tabs {
	overflow: visible;
}

.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
	z-index: 3;
}

.ant-picker-dropdown-footer-hide {
	.ant-picker-footer {
		display: none !important;
	}
}

.ant-tooltip {
	z-index: 900;
}
