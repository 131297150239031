.courier-status {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: $color-gray;
	display: inline-block;
	margin-right: 10px;

	&--busy {
		background-color: $color-success;
	}

	&--free {
		background-color: $color-yellow;
	}
}
