.inform {
	$this: &;
	display: flex;
	align-items: center;

	.ant-badge {
		&.is-bg {
			.ant-badge-count {
				background-color: $color-success;
			}
		}
	}

	.ant-badge-count {
		top: 4px;
	}

	&__icon {
		width: 44px;
		height: 44px;
		border-radius: 50%;
		background-color: transparent;
		border: 1px solid $color-light;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: border-color 0.3s;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	&__record {
		color: #fff;
		font-size: 16px;

		strong {
			font-size: 18px;
		}
	}

	&--button {
		cursor: pointer;

		&:hover {
			#{$this}__icon {
				border-color: $color-main;
			}
		}
	}
}
