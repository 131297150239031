.form {
	$this: &;

	&__label {
		font-size: 14px;
		text-transform: uppercase;
		color: $color-black;
		font-weight: 700;
	}

	&__item {
		margin-bottom: 20px;

		&--column {
			flex-direction: column;
			align-items: start;

			.ant-col {
				width: 100%;
			}

			.ant-form-item-label {
				text-align: left;
			}
		}

		.ant-form-item-explain,
		.ant-form-item-extra {
			margin-top: 2px;
			line-height: 1.3;
		}

		.ant-form-item-label > label {
			height: auto;
			font-size: 14px;
			text-transform: uppercase;
			color: $color-black;
			font-weight: 700;
		}

		.ant-form-item-control-input {
			min-height: 40px;
		}

		.ant-input {
			border-radius: 3px;
			padding: 0 10px;
			height: 40px;
			box-shadow: none !important;

			&[disabled] {
				color: rgba($color-black, 0.6);
			}
		}

		.ant-input-number-input {
			border-radius: 3px;
			padding: 0 10px;
			height: 38px;
			box-shadow: none !important;

			&[disabled] {
				color: rgba($color-black, 0.6);
			}
		}

		.ant-input-number {
			width: 100%;
			box-shadow: none !important;
		}

		.ant-input-affix-wrapper {
			box-shadow: none !important;

			.ant-input {
				height: 30px;
				padding-right: 9px !important;
			}

			.ant-input-prefix {
				margin-right: 8px;
			}
		}

		.ant-picker {
			display: flex;
			width: 100%;
			padding: 0 10px;
			height: 40px;
			border-radius: 3px;
			box-shadow: none !important;

			.ant-picker-input {
				height: 100%;

				input {
					height: 100%;
				}
			}

			&.ant-picker-range {
				.ant-picker-input {
					input {
						text-align: center;
					}
				}
			}
		}

		.ant-select {
			.ant-select-selection-search {
				left: 10px;
				right: 10px;

				input {
					height: 40px !important;
				}
			}

			.ant-select-selector {
				min-height: 40px;
				border-radius: 3px;
				padding: 0 10px;
				box-shadow: none !important;

				.ant-select-selection-item {
					display: block;
					margin-top: 5px;
				}

				.ant-select-selection-placeholder {
					line-height: 38px;
				}
			}

			.ant-select-selection-placeholder {
				line-height: 40px;
			}

			&.ant-select-multiple {
				.ant-select-selection-search {
					margin: 0;
					left: 0;
				}
				.ant-select-selection-item-content {
					vertical-align: middle;
				}
				.ant-select-selection-item-remove {
					vertical-align: middle;
				}
			}

			&.ant-select-show-search {
				.ant-select-selector {
					padding: 0;
				}

				.ant-select-selection-placeholder {
					padding: 0 10px !important;
				}

				.ant-select-selection-search {
					left: 0;
					right: 0;

					input {
						padding: 0 10px !important;
						height: 38px !important;
					}
				}

				.ant-select-selection-item {
					padding: 0 10px !important;
				}
			}
		}

		.ant-form-item-label {
			padding-bottom: 3px;
		}

		.ant-radio-group {
			overflow: hidden;
			margin-left: -8px;
			margin-top: -8px;

			.ant-radio-button-wrapper {
				height: 40px;
				line-height: 40px;
				border-radius: 20px;
				border-left-width: 1px;
				margin-left: 8px;
				margin-top: 8px;
				padding: 0 12px;

				&::before {
					display: none;
				}
			}
		}

		.ant-switch {
			min-width: 60px;
			height: 30px;

			.ant-switch-handle {
				width: 26px;
				height: 26px;

				&::before {
					border-radius: 13px;
				}
			}

			.ant-switch-inner {
				margin-left: 35px;
				margin-right: 12px;
			}

			&.ant-switch-checked {
				.ant-switch-handle {
					left: calc(100% - 26px - 2px);
				}

				.ant-switch-inner {
					margin-left: 12px;
					margin-right: 35px;
				}
			}
		}

		&--textarea {
			.ant-input {
				padding: 8px 10px;
				min-height: 100px;
			}
		}

		&--textarea-map {
			.ant-input {
				padding: 8px 10px;
				min-height: 180px;
			}
		}

		&--textarea-client {
			.ant-input {
				padding: 8px 10px;
				min-height: 120px;
			}
		}

		&--autosize {
			.ant-input {
				padding: 8px 10px;
				min-height: 40px !important;
			}
		}

		&--checkbox {
			.ant-form-item-control-input {
				min-height: auto;
			}
		}
	}

	&__dropdown {
		background: $color-white;
		border-radius: 3px;
		padding: 0;

		.ant-select-item,
		.ant-select-item-empty {
			min-height: 0;
			padding: 8px 10px;
			color: $color-black;

			&.ant-select-item-option-selected {
				background: $color-light;
				color: $color-black;
			}

			&.ant-select-item-option-active {
				color: $color-black;
			}

			&.ant-select-item-option-disabled {
				opacity: 0.6;
			}
		}

		.ant-select-item-option-content {
			white-space: normal;
		}
	}

	&__button {
		padding-top: 10px;
	}

	&__submit {
		padding-top: 30px;
	}

	&__addon {
		cursor: pointer;
		transition: color 0.3s;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 12px;
			height: 12px;
			fill: currentColor;
		}

		&:hover {
			color: $color-main;
		}
	}

	&--small {
		#{$this}__item {
			margin-bottom: 0;
		}
	}

	&__upload-text {
		margin-bottom: 10px;
	}

	&.ant-form-horizontal {
		#{$this}__item {
			align-items: center;

			.ant-form-item-label {
				padding-bottom: 0;
			}
		}
	}
}
