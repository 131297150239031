$color-black: #000000;
$color-white: #ffffff;
$color-success: #179e15;
$color-error: #e31e24;

$color-main: #1890ff;
$color-main-light: #c2dcf5;
$color-main-lightest: #dcebff;
$color-select: #d2d2d2;
$color-select-light: #fafafa;
$color-dark: #34363c;
$color-gray: #9e9e9e;
$color-gray-light: #f7f7f7;
$color-light: #e8e8e8;
$color-success-light: #c7f5c4;
$color-red-light: #ffc4c4;
$color-red-lightest: #fddcdc;
$color-yellow: #f8f83c;
$color-yellow-light: #ffffe6;

$font-family-main: 'Roboto', 'Arial', serif;
$font-family-code: monospace;
