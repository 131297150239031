.ant-notification {
	.ant-notification-notice-close {
		top: 10px;
		right: 10px;
		display: block;
		fill: $color-gray;
		transition: fill 0.3s;

		.ant-notification-close-x {
			display: block;
			width: 10px;
			height: 10px;

			svg {
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		&:hover {
			fill: $color-black;
		}
	}

	.ant-notification-notice-message {
		margin-bottom: 0;
	}

	.ant-notification-notice {
		&.is-success {
			background-color: $color-main;

			.ant-notification-notice-message {
				color: $color-white;
			}

			.ant-notification-notice-close {
				fill: $color-light;

				&:hover {
					fill: $color-white;
				}
			}
		}

		&.is-error {
			background-color: $color-error;

			.ant-notification-notice-message {
				color: $color-white;
			}

			.ant-notification-notice-close {
				fill: $color-light;

				&:hover {
					fill: $color-white;
				}
			}
		}
	}
}
