* {
	box-sizing: border-box;

	&::-webkit-scrollbar-button {
		display: none;
	}

	&::-webkit-resizer {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		transition: background-color 0.3s;
	}

	&::-webkit-scrollbar-track {
		border-radius: 3px;
	}

	@include scrollbar(4px);
}

html {
	font-family: $font-family-main;
	line-height: 1.5;
	background-color: $color-white;
	color: $color-black;
	height: 100%;
	width: 100%;
	font-size: 87.5%;
	overflow-x: hidden;

	@include microbe-media($microbe-breakpoint-df) {
		font-size: 93.75%;
	}

	@include microbe-media($microbe-breakpoint-xl) {
		font-size: 100%;
	}
}

body {
	min-width: 320px;
	width: 100%;
	height: 100%;
	margin: 0;
}

svg {
	transition: fill 0.3s;

	use {
		pointer-events: none;
	}

	button &,
	[role='button'] &,
	[href] & {
		pointer-events: none;
	}
}

picture {
	display: block;
}

img {
	max-width: 100%;
	height: auto;
	display: block;
}

iframe {
	border-width: 0;
}

fieldset {
	padding: 0;
	border: none;
}

button {
	border: none;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	padding: 0;
}

#root {
	height: 100%;
	width: 100%;
}

.hidden-element {
	padding: 5px;
	font-size: 10px;
	white-space: nowrap;
	position: absolute;
	z-index: -1;
	visibility: hidden;
	display: inline-block;
}
