.call-modal {
	position: fixed;
	bottom: 0;
	left: 270px;
	display: none;
	z-index: 100;
	background-color: $color-white;
	width: 450px;
	max-width: 70vw;
	min-height: 466px;
	box-shadow: 3px 3px 20px 0 #868686;

	&__content {
		padding: 0 18px 16px;
	}

	&__card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 20px 20px;

		& > * {
			width: 100%;
		}
	}

	&__title {
		font-size: 18px;
		font-weight: bold;
		color: $color-black;
		text-align: center;
	}

	&__block {
		padding-top: 14px;
	}

	&__audio {
		display: none;
	}

	&__panel {
		width: 152px;
	}

	&__input {
		text-align: center;
	}

	&__status {
		&.ant-switch {
			min-width: 60px;
			height: 30px;
			background-color: $color-error;

			.ant-switch-handle {
				width: 26px;
				height: 26px;

				&::before {
					border-radius: 13px;
				}
			}

			.ant-switch-inner {
				margin-left: 35px;
				margin-right: 12px;
			}

			&.ant-switch-checked {
				background-color: $color-success;

				.ant-switch-handle {
					left: calc(100% - 26px - 2px);
				}

				.ant-switch-inner {
					margin-left: 12px;
					margin-right: 35px;
				}
			}
		}
	}

	.ant-tabs-nav {
		.ant-tabs-tab {
			padding: 8px 8px;
			margin: 0 10px 0 10px;
		}
	}

	&.is-collapsed {
		left: 90px;
	}

	&.is-show {
		display: block;
	}
}
