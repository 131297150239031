.related-products {
	margin-top: 10px;
	padding: 10px 20px;
	border-radius: 10px;
	background-color: $color-select-light;

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin-left: -8px;
		margin-top: -8px;
	}

	&__col {
		padding-left: 8px;
		padding-top: 8px;
		width: 100%;

		@include microbe-media(640px) {
			width: 50%;
		}

		@include microbe-media(1281px) {
			width: 25%;
		}
	}
}
