.block {
	width: 100%;
	text-align: right;

	&__container {
		text-align: left;
	}

	&__button {
		display: inline-block;
		position: sticky;
		bottom: 20px;
		z-index: 10;
		margin-top: 50px;
	}
}
