.search-block {
	margin-bottom: 20px;

	&__title {
		text-transform: uppercase;
		color: $color-black;
		font-weight: 700;
		font-size: 14px;
		line-height: 1.5715;
		padding-bottom: 3px;
	}

	&__content {
		position: relative;

		.ant-select {
			width: 100%;

			.ant-input-affix-wrapper {
				box-shadow: none !important;
			}

			.ant-select-selection-search {
				left: 20px;

				.ant-select-selection-search-input {
					height: 40px;
				}
			}

			.ant-select-selector {
				height: 40px;
				border-radius: 3px;
				box-shadow: none !important;

				.ant-select-selection-item {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	&__button {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}
}
